export const environment = {
    production: true,
    logErrorInConsole: false,
    environmentName: "production",
    version: require("../../package.json").version,
    httpRequestTimeoutSeconds: 120,
    utcDateFormat: "yyyy-MM-dd HH:mm:ss",
    preferredDateFormat: "yyyy-MM-dd HH:mm:ss",
    oauthBaseAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/auth/",
    usersAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/users/",
    rolesAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/roles/",
    organizationsAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/organizations/",
    profileAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/profile/",
    resetPasswordAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/resetpassword/",
    languagesAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/languages/",
    smtpMailAccountsAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/mailaccounts/",
    mailtemplatesAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/mailtemplates/",
    firebaseConfigAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/firebaseconfig/",
    firebaseDevicesAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/firebasedevices/",
    firebaseNotificationsAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/firebasenotifications/",
    firebaseNotificationTemplatesAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/firebasenotificationtemplates/",
    systemLogMessagesAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/systemlogs/",
    systemJobPerformancesAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/systemjobs/",
    systemResourceSnapshotsAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/systemresource/",
    systemErrorsAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/systemerrors/",
    postcodeAreasAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/postcodeareas/",
    postcodeUsersAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/postcodeusers/",
    flyerImagesAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/flyerimages/",
    checklistsAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/checklists/",
    checklistTemplateAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/checklisttemplates/",
    interestedPartiesAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/interestedparties/",
    distributorsAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/distributors/",
    shopItemsAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/shopitems/",
    orderItemsAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/orderitems/",
    visitReportsAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/admin/visitreports/",
    toursAPI: "https://prodwww.conmoto.vcl4.kunden.csl.de/api/v1/admin/tours/"
};
